import request from '@/utils/request_API';
import constants from '@/utils/constants';

export function getORIONSubscriptions() {
	return request({
		url: 'orion/getSubscriptions',
		method: constants.METHOD_GET
	});
}

export function createORION_Subscription(entity) {
	return request({
		url: 'orion/createSubscriptions',
		method: constants.METHOD_POST,
		data: entity,
		headers: {
			'Content-Type': constants.Application_Json,
			Accept: constants.Application_Json
		}
	});
}

export function deleteSubscriptionById(ID) {
	return request({
		url: 'orion/subscription/' + ID,
		method: constants.METHOD_DELETE
	});
}
