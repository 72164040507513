<template>
	<div :style="appearance">
		<v-tabs v-model="tab">
			<v-tab>{{ $t('orion.subscriptions') }} </v-tab>
		</v-tabs>

		<v-tabs-items v-model="tab">
			<v-tab-item><SubscriptionList /></v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import SubscriptionList from './component/subscriptionList.vue';
export default {
	name: 'orionmanagement',
	components: {
		SubscriptionList
	},
	data() {
		return {
			appearance: {
				height: '100%',
				width: '100%',
				overflow: 'scroll',
				position: 'absolute'
			},
			tab: null
		};
	},
	beforeCreate() {
		this.$vuetify.lang.current = this.$store.getters.getSession.language;
	},
	methods: {}
};
</script>

<style lang="postcss" scoped></style>
